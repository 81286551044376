<template>
    <painel titulo="Email" icone="pi pi-key" :refreshFunction="obterEmail">
        <div class="mb-4">
            <detalhe :size="cabecalho" titulo="Módulo">{{ email?.modulo }}</detalhe>
            <detalhe :size="cabecalho" titulo="SubMódulo">{{ email?.subModulo }}</detalhe>
            <detalhe :size="cabecalho" titulo="Destinatários">{{ email?.destinatarios }}</detalhe>
            <detalhe :size="cabecalho" titulo="Título">{{ email?.titulo }}</detalhe>
            <detalhe :size="cabecalho" titulo="Atualizado em">{{ $dateFormat(email?.dataAtualizacao, 'DD/MM/YYYY HH:mm') }}</detalhe>
            <detalhe :size="cabecalho" titulo="Status"><status :status="email?.status"></status></detalhe>
            <detalhe :size="cabecalho" v-if="email?.erro" titulo="Erro">{{ email?.erro }}</detalhe>
        </div>
        <btn-voltar :route="{ name: 'GestaoEmails_Emails' }"></btn-voltar>
        <btn-exibir :email="email"></btn-exibir>
    </painel>
</template>

<script>
import EmailsServices from './services';
import BtnExibir from './BtnExibir';

export default {
    components: {
        BtnExibir,
    },

    data() {
        return {
            email: null,
            cabecalho: '120',
        };
    },

    methods: {
        obterEmail() {
            this.$store.dispatch('addRequest');
            EmailsServices.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.email = response.data;
                } else {
                    this.email = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        voltar() {
            this.$router.push({ name: 'GestaoEmails_Emails' });
        },
    },

    mounted() {
        this.obterEmail();
    },
};
</script>
