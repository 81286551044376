import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GESTAOEMAILS_PORT}${process.env.VUE_APP_API_PATH}/gestaoemails`;
const urlBase = `${api}/emails`;

export default {
    obterTodos(filtro) {
        return axiosJwt.get(`${urlBase}?dataAtualizacaoDe=${filtro.dataAtualizacaoDe}&dataAtualizacaoAte=${filtro.dataAtualizacaoAte}`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${urlBase}/${id}`);
    },
};
    