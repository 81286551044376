<template>
    <Button label="Exibir Email" class="ml-2" icon="pi pi-external-link" @click="abrir()" v-if="$temAcessoView('EMAIL-DETALHAR-01')" />
    <Dialog :header="email?.titulo" v-model:visible="mostrar" :breakpoints="{ '960px': '75vw', '640px': '100vw' }">
        <div v-html="email?.mensagem"></div>
        <template #footer>
            <Button label="Fechar" icon="pi pi-times" @click="fechar()" class="p-button-text mt-4" />
        </template>
    </Dialog>
</template>

<script>
export default {
    props: {
        email: {
            type: Object,
        },
    },

    data() {
        return {
            mostrar: false,
        };
    },

    methods: {
        abrir() {
            this.mostrar = true;
        },

        fechar() {
            this.mostrar = false;
        },
    },
};
</script>
